'use strict';

$(document).ready(function() {
  // mobile menu

  $(".js-mobileNavigation").on('click', function(e) {
    e.preventDefault();
    // $(".navigation").fadeToggle(200);
    $(".header-menu").toggleClass('header-menu--activated');
    $(this).toggleClass('is-active');
  });

});
